import Login from './Login.vue';
Symbol.useSimple();


import "vuetify/dist/vuetify.min.css";
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
Vue.use(Vuetify);

new Vue({
    name: "App",
    render: h => h(Login),
    vuetify: new Vuetify()
}).$mount("#app");