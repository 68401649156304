<template>
    <v-app>
        <v-main>
            <unsupported-browser-warning></unsupported-browser-warning>

            <v-container fill-height>
                <v-card elevation="0" class="mx-auto v-sheet theme--light" width="350" color="#142D56" id="LoginForm" :class="{'shake' : badLogin}">
                    <v-sheet color="#142D56" class="pa-5" tile>
                        <v-img class="mx-auto" width="94" contain src="/Areas/NewUI/Content/img/Diva logo.svg"></v-img>
                    </v-sheet>
                    <v-card-text>
                        <transition name="fade" id="hintText">
                            <p class="white--text text-center mb-8" v-if="showHint"> Proven clinical audit software that enhances patient care and promotes professional development </p>
                        </transition>

                        <v-text-field solo v-model="username" type="text"
                                      name="input-username" label="Username" value=""
                                      @click:append="show3 = !show3" @keyup.enter="loginFunction" autofocus>
                        </v-text-field>

                        <v-text-field solo v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                      :type="showPassword ? 'text' : 'password'"
                                      name="input-password" label="Password" value=""
                                      @click:append="showPassword = !showPassword" @keyup.enter="loginFunction">

                        </v-text-field>

                        <v-row justify="end" class="mb-5 mt-2 mr-0">
                            <v-btn color="#EE71F0" class="white--text" v-on:click="loginFunction">Log in</v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-container>
        </v-main>
    </v-app>
</template>

<style>
    [v-cloak] {
        display: none;
    }

    #app {
        background-color: #142D56;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.8s;
    }

    .fade-enter,
    .fade-leave-to
    /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    .shake {
        animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    }

    @keyframes shake {

        10%, 90% {
            transform: translateX(-1px);
        }

        20%, 80% {
            transform: translateX(2px);
        }

        30%, 50%, 70% {
            transform: translateX(-4px);
        }

        40%, 60% {
            transform: translateX(4px);
        }
    }
</style>

<script>
    export default {
        name: 'Login',
        components: {
            UnsupportedBrowserWarning: () => ({
                component: import("@/components/UnsupportedBrowserWarning"),
            }),
        },
        data: () => ({
            showHint: false,
            showPassword: false,
            password: "",
            username: "",
            badLogin: false,
            rules: {
                required: function (value) { return !!value || "Required."; },
                min: function (v) { return v.length >= 8 || "Min 8 characters"; },
                emailMatch: function () { return "The email and password you entered don't match"; }
            },
        }),
        created: function () {
            setTimeout(function () { this.showHint = true; }.bind(this), 30000);
        },
        methods: {
            loginFunction: function () {
                var formData = new FormData();
                formData.append("username", this.username);
                formData.append("password", this.password);

                var xmlHttp = new XMLHttpRequest();
                xmlHttp.onreadystatechange = function () {
                    if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                        let json = JSON.parse(xmlHttp.responseText).result;
                        if (json.login === true) {
                            if (json.redirect != null) window.location.href = json.redirect;
                            else window.location.href = '/NewUI/DataEntry';
                        }
                        else {
                            this.badLogin = true;
                            setTimeout(
                                function () {
                                    this.badLogin = false;
                                }.bind(this),
                                1000
                            );
                        }
                    }
                }.bind(this);
                xmlHttp.open("post", "/NewUI/Auth/CheckLogin");
                xmlHttp.send(formData);
            }
        }
    };
</script>
